import React, {useState} from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import AssetMetadata from './AssetMetadata'
import styled, { consolidateStreamedStyles } from 'styled-components';
import { connectWallet } from '../../constants';
import { OrderSide } from 'opensea-js/lib/types';
import OpenSeaLogo from './img/opensea-logomark.png';
import SalePrice from '../common/SalePrice';
import { WyvernSchemaName } from "opensea-js/lib/types";
import * as XLSX from 'xlsx';
import f  from '../../critics/critics.xlsx';
import parse from 'html-react-parser';
import "react-datepicker/dist/react-datepicker.css";

const Card = styled.div.attrs({ className: "card mx-2 mb-4" })`
  width : 100%;
  min-width: 200px;
  background : #fff;
  border : none;
  padding : 10px;
  border : solid 1px #640106;
  img {
    height: 200px;
    max-width: 100%;
  }
  img.small {
    max-width: 50%;
    height: 60px;
  }
  a h5.card-title{
    color : #640106;
    text-align : center;
    margin-bottom : 0px;
    font-weight : bold;
    font-size: 18px;
  }

  .list-group-item{
    background : none;
    text-align : center;
    padding : 0;
    color : #e00613;
    font-size : 14px;
  }

  .list-group-flush{
    border : none;
  }

  .card-body{
    padding-bottom : 0px;
    padding-top : 10px;
  }

  .card-footer{
    background : none;
    color : #fff;
    padding : 0px;
  }

  .card-footer .text-muted{
    text-align : center;
    margin : auto;
    display : block;
    color : #e00613 !important;
  }
`

export default class Asset extends React.Component {

  state = {
    errorMessage: null,
    creatingOrder: false,
    creatingOffer: false,
    orderState : null,
    order : null,
    priceOffer : 0,
    dataFetch : null,
    fetched : false,
    dateOffer : new Date(),
  }

  static propTypes = {
    currentAccount: PropTypes.object,
    seaport: PropTypes.object.isRequired,
    accountAddress: PropTypes.string,
    assetContractAddress : PropTypes.string,
    token_id : PropTypes.string,
    owner : PropTypes.string,
    orderby: PropTypes.string,
    singleAsset : PropTypes.bool,
    order : PropTypes.object,
    asset : PropTypes.object,
    full : PropTypes.bool.isRequired
  }

  componentDidMount(){
    this.fetchEventsAsset(this.props.asset)
  }

  onError(error) {
    // Ideally, you'd handle this error at a higher-level component
    // using props or Redux
    this.setState({ errorMessage: error.message })
    setTimeout(() => this.setState({errorMessage: null}), 3000)
    throw error
  }

  async fulfillOrder() {
    const { accountAddress, order } = this.props
    console.log(order)
    if (!accountAddress) {
      await connectWallet()
    }
    try {
      this.setState({ creatingOrder: true })
      await this.props.seaport.fulfillOrder({ order, accountAddress })
    } catch(error) {
      this.onError(error)
    } finally {
      this.setState({ creatingOrder: false })
    }
  }

  async makeOffer(price) {
    const { accountAddress, order } = this.props
    if (!accountAddress){
      await connectWallet()
    }
    try {
      this.setState({ creatingOffer: true })
      console.log("DKHASKJDHASJKDHASJKDHASJKDHAKJDHAJSKDHAKJSHDKAJSHDSAJHDASH")
      const buyOrder = await this.props.seaport.createBuyOrder({
        asset: {
          tokenId : order.tokenId,
          tokenAddress : order.tokenAddress,   
          schemaName : WyvernSchemaName,
        },
        accountAddress : accountAddress,    
        startAmount : price,
        expirationTime: Math.round(this.state.dateOffer),

      })
    } catch (error) {
      this.onError(error)
    } finally {
      this.setState({ creatingOffer: false })
    }
  }

  renderBuyButton(canAccept = true) {
    const { creatingOrder } = this.state
    const { accountAddress, order } = this.props
    var owned = accountAddress && !canAccept
    const buyAsset = async () => {
      if (accountAddress && !canAccept) {
        this.setState({
          errorMessage: "You already own this asset!"
        })
        return
      }
      this.fulfillOrder()
    }
    if(!owned){
    return (
      <button
        disabled={creatingOrder}
        onClick={buyAsset}
        className="btn btn-primary w-100">
        
        Buy{creatingOrder ? "ing" : ""} for <SalePrice order={order} />

      </button>
    )
    }else{
      return(
        <p>owned</p>
      )
    }
  }

  renderAcceptOfferButton(canAccept = true) {
    const { creatingOrder } = this.state
    const { accountAddress, order } = this.props
    var owned = !(accountAddress && !canAccept)
    console.log(order)
    const sellAsset = async () => {
      if (accountAddress && !canAccept) {
        this.setState({
          errorMessage: "You do not own this asset!"
        })
        return
      }
      this.fulfillOrder()
      }

    if(owned){
    return (
      <button
        disabled={creatingOrder}
        onClick={sellAsset}
        className={`btn btn-success w-100`}>

        Sell for <SalePrice order={order} />

      </button>
    )
    }else{
      //Make offer
      console.log("make offer")
      return
    }
  }

  renderAcceptListings(canAccept = true){
  
  }

  renderListings(){

  }

  changePrice = (event) => {
    this.setState({priceOffer : event.target.value})
  }

  renderMakeOfferButton(canAccept = true){
    /*const { accountAddress, order } = this.props
    var owned = accountAddress && !canAccept

    const makeOffer = async () =>{
     if (accountAddress && !canAccept){
       this.setState({errorMessage : "You already own this asset!"})
       return
     }

     this.makeOffer(this.state.priceOffer)
    }

    if(!owned){
      return (
        <form>
          <label>Price in ETH : </label>
          <input type="number" onChange={this.changePrice} step="0.0001" />
          <button
            onClick={makeOffer}
            className="btn btn-primary w-100">
            Make offer
          </button>
          
        </form>
      )
    }*/
  }

  convertToEth(value){
    return Number(value * 0.000000000000000001).toFixed(2);
  }

  formatDate(string){
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(string).toLocaleDateString([],options);
}

  fetchEventsAsset(asset){
    const options = {method: 'GET'};
    
    fetch('https://api.opensea.io/api/v1/events?asset_contract_address='+ asset.assetContract.address +'&token_id='+ asset.tokenId +'&only_opensea=false&offset=0', options)
      .then(response => response.json())
      .then(json => {console.log(json); this.setState({dataFetch : json}); this.setState({fetched : true})})
      .catch(err => console.error(err));
  }

  getEventType(event){
    //Created
    if(event.event_type == "transfer"){
      if(event.from_account.user != null){
        if (event.from_account.user.username == 'NullAddress'){
          return 'Created';
        }
      }
      // Transfert
      return 'Transfert';
    }

    if(event.event_type == 'successful'){
      return 'Sale';
    }

    if(event.event_type == 'created'){
      return 'List';
    }

    if(event.event_type == 'offer_entered'){
      return 'Offer';
    }

    if(event.event_type == 'cancelled'){
      return 'Cancel';
    }

    if(event.event_type == 'bid_entered'){
      return 'Bid';
    }

    if(event.event_type == 'bid_withdrawn'){
      return 'Bid Cancel';
    }

  }

  renderTradingHistory(asset){
    var result = '';
    if(this.state.dataFetch != null){
      console.log(this.state.dataFetch);
      var response = this.state.dataFetch;
      result += '<div class="title-trading-view"><h2>Trading view</h2></div>'
      result += '<div class="event-container">';
        result += '<div class="title-event-container">';
          result += '<div class="title-event-type">Event</div>';
          result += '<div class="title-event-price">Price</div>';
          result += '<div class="title-event-from">From</div>';
          result += '<div class="title-event-to">To</div>';
          result += '<div class="title-event-date">Date</div>';
        result += '</div>';
        console.log(response.asset_events)
      for( let i = 0; i < response.asset_events.length; i++){
        result += '<div class="event-single">';
          result += '<div class="event-type">';
            result += '<p>'+ this.getEventType(response.asset_events[i]) +'</p>'
          result += '</div>';

          result += '<div class="event-price">';
          console.log(response.asset_events[i].payment_token)
            if(response.asset_events[i].bid_amount != null){
              if(response.asset_events[i].payment_token.symbol == 'ETH' || response.asset_events[i].payment_token.symbol == 'WETH'){
                result += '<p>'+ '<span>Ξ</span>' + this.convertToEth(response.asset_events[i].bid_amount) + '</p>';
              }else{
                result += '<p>'+ response.asset_events[i].payment_token.symbol + ' ' + this.convertToEth(response.asset_events[i].bid_amount) + '</p>';
              }
            }else if(response.asset_events[i].ending_price != null){
              if(response.asset_events[i].payment_token.symbol == 'ETH' || response.asset_events[i].payment_token.symbol == 'WETH'){
                result += '<p>' + '<span>Ξ</span>' + this.convertToEth(response.asset_events[i].ending_price) + '</p>';
              }else{
                result += '<p>'+ response.asset_events[i].payment_token.symbol + ' ' + this.convertToEth(response.asset_events[i].ending_price) + '</p>';
              }
            }
          result += '</div>';

          result += '<div class="event-from">';
            //NEED TO TEST IF SUCCESSFUL -> IF TRUE : GET I-1 (FROM TRANSFERT EVENT) // AND NEED TO ADD PRICE TOTAL_PRICE
            if(response.asset_events[i].from_account != null){
              if(response.asset_events[i].from_account.user == null){
                result += '<p>' + response.asset_events[i].from_account.address.substring(2,8) + '</p>';
              }else{
                result += '<p>' + response.asset_events[i].from_account.user.username + '</p>';
              }
            }
            if(this.getEventType(response.asset_events[i]) == 'Sale'){
              if(response.asset_events[i].seller != null){
                if(response.asset_events[i].seller.user != null){
                  result += '<p>' + response.asset_events[i].seller.user.username + '</p>';
                }else{
                  result += '<p>' + response.asset_events[i].seller.address.substring(2,8) + '</p>';
                }
              }
            }

          result += '</div>';

          result += '<div class="event-to">';
            if(response.asset_events[i].to_account != null){
              if(response.asset_events[i].to_account.user == null){
                result += '<p>' + response.asset_events[i].to_account.address.substring(2,8) + '</p>';
              }else{
                result += '<p>' + response.asset_events[i].to_account.user.username + '</p>';
              }
            }

            if(this.getEventType(response.asset_events[i]) == 'Sale'){
              if(response.asset_events[i].winner_account != null){
                if(response.asset_events[i].winner_account.user != null){
                  result += '<p>' + response.asset_events[i].winner_account.user.username + '</p>';
                }else{
                  result += '<p>' + response.asset_events[i].winner_account.address.substring(2,8) + '</p>';
                }
              }
            }

          result += '</div>';

          result += '<div class="event-date">';
            result += '<p>' + moment(response.asset_events[i].created_date).format('hh:mm:ss MM-DD-YYYY') + '</p>';
          result += '</div>';

        result += '</div>';
      }
      result += '</div>';
    }

    return(
      parse(result)
    )
  }



  renderExpirationBadge() {
    const expirationTime = parseFloat(this.props.order.expirationTime)

    if (expirationTime <= 0) {
      return null;
    }

    const timeLeft = moment.duration(moment.unix(expirationTime).diff(moment()))

    return (
      <span className="badge bid-expiry-badge red">
        <i className="tiny material-icons">timer</i>
        <span className="expire-label">Expires in </span>
        {timeLeft.humanize()}
      </span>
    )
  }


  render() {
    const { errorMessage } = this.state
    var { accountAddress, asset, order } = this.props
    var owner = null

    const backgroundPage={
      backgroundColor : '##F0F2F5',
      minHeight : '100vh'
    }


    if(asset != null){
      owner = asset.owner;
    }

    if(order != null && asset != null){
      owner = asset.owner;
    }else if(order != null && asset == null){
      owner = order.asset.owner;
      asset = order.asset
    }

    var usernameOwner = ''
    var isOwner = false;

    if(owner != null){
      if(owner.user == null || owner.user.username == null){
        usernameOwner = owner.address.substring(2,8);
      }else if (owner.user.username == 'NullAddress'){
        usernameOwner = 'Multiple owner'
      }else{
        usernameOwner = owner.user.username
      }

      if(asset.sellOrders[0] != null){
        isOwner = accountAddress && accountAddress.toLowerCase() === asset.sellOrders[0].maker
      }else{
        isOwner = accountAddress && accountAddress.toLowerCase() === owner.address.toLowerCase()
      }

    }
    
    var collection = asset.assetContract.name

    if(collection != null){
      if(collection.length > 15){
        collection = asset.assetContract.name.substring(0,15) + " [...]";
      }
    }


    if(!this.props.singleAsset){
      return (
        <Card>
          <AssetMetadata asset={asset} /> 
          
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              Owned by <b>{usernameOwner}</b>
            </li>
          </ul>
          <div className="card-footer">
            <small className="text-muted">Collection : <b>{collection}</b></small>
          </div>
          {this.renderExpirationBadge}
        </Card>
      )
    }else{
      /*var name = f.name;
      const reader = new FileReader();
      reader.onload = (evt) => { // evt = on_file_select event
          /* Parse data */
          /*const bstr = evt.target.result;
          const wb = XLSX.read(bstr, {type:'binary'});
          /* Get first worksheet */
        /*  const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
        /*  const data = XLSX.utils.sheet_to_csv(ws, {header:1});
          /* Update state */
         /* console.log("Data>>>"+data);
      };
      reader.readAsBinaryString(f);*/
      console.log("isOwner : " + isOwner)
      return(
        <div>
        {asset != null
          ? <React.Fragment> 
            <Section style={backgroundPage}>
                <div class="container">
                  <div class="asset-informations">
                    <div class="two-col">
                      { /*image*/ }
                      <img src={asset.imageUrlOriginal}></img>
                    </div>
                    <div class="two-col">
                      { /* Title - Description - Owner - Creator - Price */}
                      <h1>{ asset.name }</h1>
                      {console.log(asset)}
                      <a target="_blank" href={asset.openseaLink}><img src={OpenSeaLogo} class="opensea-button"></img></a>
                      <h4>Collection</h4> 
                      <p>{asset.assetContract.name}</p>
                      <h4>Description</h4>
                      <p>{asset.description === null
                          ? "No description found"
                          : asset.description
                          }</p>
                      <h4>Owner</h4>
                      <p><img class="profile_img" src={asset.owner.profile_img_url}></img> {usernameOwner}</p>
                      {order != undefined
                      ? <p>Price : 
                        {order.side === OrderSide.Buy
                          ? this.renderAcceptOfferButton(isOwner)
                          : null
                        }
                        {order.side === OrderSide.Sell
                          ? this.renderBuyButton(!isOwner)
                          : null
                        }</p>
                      : null
                      }
                        <p>Make Offer
                        {this.renderMakeOfferButton(!isOwner)}
                        </p>
   
                    </div>
                    {this.renderTradingHistory(asset)}
                  </div>
                  {/*console.log("asset : " + JSON.stringify(asset)) */}
                  {console.log("error : " + errorMessage)}
                  

                  
                </div>
            </Section>
            </React.Fragment>
          : <h1>Not found</h1>
        }
      </div>
      )
    }
  }
}

const Section = styled.section`

h1{
  padding-bottom : 30px;
}

.asset-informations{
  margin-top : 50px;
}

.asset-informations h4{
  text-decoration : underline;
}

.opensea-button{
  max-width : 50px;
  margin-bottom : 15px;
}

.two-col{
  width : 50%;
  display : inline-block;
  height : 100%;
  vertical-align : top;
  padding : 20px;
}

.two-col:first-child{
  padding-left : 0px;
}

.two-col:last-child{
  padding-right : 0px;
}

.two-col p{
  padding-right : 20px;
  word-wrap : break-word;
}
.two-col:last-child p{
  padding-right : 0px;
}

.profile_img{
  max-height : 30px;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  color: #b90000;
  background-color: #ffffff;
  border-color: #b90000;
  font-weight : bold;
}

.btn-primary:active:focus{
  box-shadow : none !important;
}

.btn-primary:focus{
  box-shadow : none !important;
}

.btn-primary{
  color: #ffffff;
  background-color: #b90000;
  border-color: #b90000;
  font-weight : bold; 
}

.btn-primary:hover {
  color: #b90000;
  background-color: #ffffff;
  border-color: #b90000;
  font-weight : bold;
}

.event-container{
  max-height : 300px;
  overflow-y : scroll;
}

.title-event-container{
  position :sticky;
  top : 0px;
}

.title-event-type, .title-event-price, .title-event-from, .title-event-to, .title-event-date, .event-type, .event-price, .event-from, .event-to, .event-date{
  display : inline-block;
}

.title-event-type, .event-type, .title-event-price, .event-price{
  width : 15%;
}

.title-event-from, .title-event-to, .event-from, .event-to{
  width : 25%;
}

.title-event-date, .event-date{
  width : 20%;
}

`  