import React from "react";

function Footer() {
  return (
    <div>
      <h1>This is footer</h1>
    </div>
  );
}

export default Footer;