import React from 'react';
import styled from 'styled-components';
import Logo from '../../Navigation/img/JapanNFT.png'
import './index.css';


export default class MyArt extends React.Component {

  render() {
    const backgroundPage={
      backgroundColor : '#F0F2F5'
    }
    return (
      <main>
        <Section style={backgroundPage}>
          <div class="container">
            <div class="about-section">
              <div class="title-page">
                <img src={Logo}></img>
              </div>
              <div  class="one-col">
                <h2>Izumi - NFT Gallery</h2>
                 
                <p><a href="https://app.rarible.com/nezumikozo" target="_blank">Izumi - NFT Gallery</a> is a NFT Creator and Collector based in Switzerland.<br></br></p>
                <p>Izumi's NFTs are both inspired by Japan and technology, they are full of color and have their own unique identity.<br></br></p>
                <p>In addition to creating and collecting, Izumi - NFT Gallery has a blog dedicated to criticism of the NFTs that we have acquired, various subjects are covered on each NFT found in the blog, and a 2-minute video will accompany you.<br></br></p> 
                <p>Various events are organized on our site, including the sale of our creations as well as the auctions on our collection.
                </p> 
                <p><a href="https://app.rarible.com/cryptokozo" target="_blank">Izumi NFT Gallery</a> NFTs are digital arts limited to 10pcs per NFT !</p>
                <p>In most cases they are "Unlockable" which means that with the purchase of NFT You will receive a link with the high definition printable version.</p>
                <p>Each NFT Izumi - NFT Gallery is limited to 10 editions based on the ERC 1155. Izumi(INFT) Token an ERC-1155 tokens that is hosted on Ethereum's own blockchain.<br></br></p>
                  
                <p>2021 will be the year of the great NFT.</p>

                  <p>IZUMI</p>
              </div>
            </div>
          </div>
        </Section>
       
      </main>
    )
  }
}
const Section = styled.section`

background-repeat: repeat;
padding-bottom : 50px;

min-height: 100vh;
height : 100%;
width: 100%;

.title-page{
  padding-top : 75px;
}

.title-page h1{
  display : block;
  font-size : 72px;
  padding : 50px 0px;
}

.about-section{
    padding-top : 15px;
    color : #000;
  }
  .logo{
    max-width : 550px;
    margin : auto;
    display : block;
  }
  .one-col{
    text-align : justify;
  }
  .two-col p{
    padding-right : 20px;
  }
  .two-col:last-child p{
    padding-right : 0px;
  }
`
